(function () {

    'use strict';

    /**
     * @ngdoc overview
     * @name semy
     * @description
     * semy angular app...
     *
     */
    angular
        .module('appsaurus', [
            'ngAnimate',
            'ngCookies',
            'ngResource',
            'ngSanitize',
            'ngMaterial',
            'ui.router',
            'uuid'
        ]);

})();

(function () {
    'use strict';

    angular
        .module('appsaurus')
        .controller('PageController', PageController);

    PageController.$inject = ['$log', '$stateParams', 'Colors'];

    function PageController($log, $stateParams, Colors) {

        var vm = this;
        $log.info($stateParams);
        vm.color = $stateParams.color || Colors.random();

    }

})();

(function () {
    'use strict';

    angular
        .module('appsaurus')
        .controller('DashboardController', DashboardController);

    DashboardController.$inject = ['$log', '$scope', '$timeout', '$state', '$window', 'uuid4', 'Animations', 'Colors', 'Random'];

    function DashboardController($log, $scope, $timeout, $state, $window, uuid4, Animations, Colors, Random) {

        var vm = this;

        vm.colorTiles = [];

        var colorCache = {};

        function activate() {

            $log.info('Dashboard Activate');

            var total = 51;
            var indexes = [];
            var tileCache = {};

            /// claim a priority index for a piece of content, removing it from the available indexes list.
            function uniqueIdx(priority) {
                priority = priority || indexes.length;
                priority = Math.min(priority, indexes.length);
                var idx = Random.index(priority);
                var value = indexes[idx];
                indexes.splice(idx, 1);
                return value;
            }

            var logoTile = (function () {
                var color = Colors.random();
                return {
                    color: color,
                    img: 'assets/hi.svg',
                    colspan: 3,
                    rowspan: 2,
                    title: 'About Me',
                    fun: function () {
                        $state.go('about', {color: color});
                    },
                    size: 80
                };

            })();

            var classicsTile = (function () {
                return {
                    color: '#EFE4BD',
                    img: 'assets/a-l.svg',
                    colspan: 1,
                    rowspan: 1,
                    title: 'Attikos & Logeion',
                    fun: function () {
                        $state.go('classics', {color: '#EFE4BD'});
                    },
                    size: 80
                };

            })();

            var webTile = (function () {
                var color = Colors.random();
                return {
                    color:color,
                    img: 'assets/language-24px.svg',
                    colspan: 1,
                    rowspan: 1,
                    title: 'Web',
                    fun: function () {
                        $state.go('web', {color:color});
                    },
                    size: 80
                };

            })();

            var backendTile = (function () {
                var color = Colors.random();
                return {
                    color: color,
                    img: 'assets/code-24px.svg',
                    colspan: 1,
                    rowspan: 1,
                    title: 'Backend',
                    fun: function () {
                        $state.go('backend', {color: color});
                    },
                    size: 80
                };

            })();

            var toolsTile = (function () {
                var color = Colors.random();
                return {
                    color: color,
                    img: 'assets/construction-24px.svg',
                    colspan: 1,
                    rowspan: 1,
                    title: 'Tools',
                    fun: function () {
                        $state.go('tools', {color: color});
                    },
                    size: 80
                };

            })();

            var okayTile = (function () {
                return {
                    color: '#5766FF',
                    img: 'assets/okay.svg',
                    colspan: 1,
                    rowspan: 1,
                    title:'Okay Inside',
                    fun: function () {
                        $state.go('okay', {color: '#5766FF'});
                    },
                    size: 80
                };

            })();

            var semyTile = (function () {
                return {
                    color: '#5dd7ad',
                    img: 'assets/semy.svg',
                    colspan: 1,
                    rowspan: 1,
                    title: 'Semy',
                    fun: function () {
                        $state.go('semy', {color: '#5dd7ad'});
                    },
                    size: 70
                };

            })();

            var iOSTile = {
                color: '#000000',
                img: 'assets/apple.svg',
                colspan: 1,
                rowspan: 1,
                title:'iOS',
                padding: '24px',
                fun: function () {
                    $state.go('ios', {color: '#000000'});
                },
                size: 70
            };

            var androidTile = {
                color: '#3DDC84',
                img: 'assets/robot.svg',
                colspan: 1,
                rowspan: 1,
                title:'Android',
                fun: function () {
                    $state.go('android', {color: '#3DDC84'});
                },
                size: 70
            };

            var uchicagoTile = {
                color: '#800000',
                img: 'assets/uchicago-shield.png',
                colspan: 1,
                rowspan: 1,
                title:'UChicago',
                fun: function () {
                    $state.go('uchicago', {color: '#800000'});
                },
                size: 50
            };

            var appsaurusTile = {
                color: '#91c33f',
                img: 'assets/appsaurus-1.png',
                colspan: 1,
                rowspan: 1,
                title: 'Appsaurus',
                fun: function () {
                    $state.go('appsaurus', {color: '#91c33f'});
                },
                size: 70
            };

            var connectsxTile = {
                color: '#65205a',
                img: 'assets/csx.svg',
                colspan: 1,
                rowspan: 1,
                title:'ConnectSx',
                fun: function () {
                    $state.go('connectsx', {color: '#65205a'});
                },
                size: 25
            };

            var emailTile = {
                color: Colors.random(),
                img: 'assets/email.svg',
                colspan: 1,
                rowspan: 1,
                title: 'Contact',
                padding: '36px',
                fun: function () {
                    $window.open('mailto:hi@appsaur.us?subject=Hi%20Appsaurus%21&body=Let%27s%20chat%20about%20my%20project%21', '_self');
                },
                size: 60

            };

            var shuffleTile = {
                color: Colors.random(),
                img: 'assets/shuffle.svg',
                colspan: 1,
                rowspan: 1,
                padding: '36px',
                fun: function () {
                    Random.ize(vm.colorTiles);
                },
                size: 60

            };

            var refreshTile = {
                color: Colors.random(),
                img: 'assets/refresh.svg',
                colspan: 1,
                rowspan: 1,
                padding: '36px',
                fun: function () {
                    angular.forEach(vm.colorTiles, function (tile) {
                        tile.clazz = '';
                    });
                    $timeout(function() {
                        activate();
                    });
                },
                size: 60

            };

            var paletteFun = function () {
                var count = Random.index(20);
                var speed = 250 + Random.index(250);
                for (var i=0; i <= count; i++) {
                    if (i === count) {
                        $timeout(function () {
                            angular.forEach(vm.colorTiles, function (tile) {
                                if (colorCache.hasOwnProperty(tile.id)) {
                                    tile.color = colorCache[tile.id];
                                } else {
                                    tile.color = Colors.random();
                                }
                            });
                        }, i * speed);
                    } else {
                        $timeout(function () {
                            angular.forEach(vm.colorTiles, function (tile) {
                                tile.color = Colors.random();
                            });
                        }, i * speed);
                    }
                }
            };
            var paletteTile = {
                color: Colors.random(),
                img: 'assets/palette.svg',
                colspan: 1,
                rowspan: 1,
                padding: '36px',
                fun: paletteFun,
                size: 60
            };

            var otherTiles = [
                // backendTile,
                // iOSTile,
                // androidTile,
                // webTile,
                emailTile,
                uchicagoTile,
                appsaurusTile,
                // connectsxTile,
                // toolsTile,
                // semyTile,
                okayTile,
                classicsTile,
                shuffleTile,
                refreshTile,
                paletteTile
            ];

            angular.forEach(otherTiles, function(t) {
                t.id = uuid4.generate();
                colorCache[t.id] = t.color;
            })

            /// build up even indexes for content tiles
            for (var i=1; i < 27; i++) {
                indexes.push(i);
            }

            /// pop off random even indexes for each tile
            tileCache[0] = logoTile;
            angular.forEach(otherTiles, function(t) {
                tileCache[uniqueIdx()] = t;
            })

            function tile() {
                var tiles = [];

                for (var i = 0; i < total; i++) {

                    var tile = tileCache.hasOwnProperty(i) ?
                        tileCache[i] : animated();

                    tiles.push(tile)
                }

                return tiles;
            }

            vm.colorTiles = tile();

            $timeout(function () {
                angular.forEach(vm.colorTiles, function (tile) {
                    tile.clazz = 'transition';
                });
            }, 250);


        }

        /// generates a 1x1 block that has a little animation
        function animated() {
            var animation = Animations.random();
            var id = uuid4.generate();
            return {
                id: id,
                color: Colors.random(),
                clazz: '',
                colspan: 1,
                rowspan: 1,
                fun: function () {
                    var tile = getTile(id);
                    tile.clazz = 'animated ' + animation;
                    $timeout(function () {
                        tile.clazz = '';
                    }, 500);
                }
            };
        }

        function getTile(id) {
            for (var i = 0; i < vm.colorTiles.length; i++) {
                var tile = vm.colorTiles[i];
                if (tile.id === id) {
                    return vm.colorTiles[i];
                }
            }
        }

        activate();

    }

})();

(function () {
    'use strict';

    angular
        .module('appsaurus')
        .config(routerConfig);

    routerConfig.$inject = ['$stateProvider'];

    function routerConfig($stateProvider) {

        $stateProvider
            .state('dashboard', {
                url: '/',
                templateUrl: 'app/dashboard/dashboard.html',
                controller: 'DashboardController',
                controllerAs: 'vm'
            })
            .state('about', {
                url: '/about',
                params: { color: null },
                templateUrl: 'app/pages/about.html',
                controller: 'PageController',
                controllerAs: 'vm'
            })
            .state('appsaurus', {
                url: '/appsaurus',
                params: { color: null },
                templateUrl: 'app/pages/appsaurus.html',
                controller: 'PageController',
                controllerAs: 'vm'
            })
            .state('okay', {
                url: '/okayinside',
                params: { color: null },
                templateUrl: 'app/pages/okay.html',
                controller: 'PageController',
                controllerAs: 'vm'
            })
            .state('classics', {
                url: '/classics',
                params: { color: null },
                templateUrl: 'app/pages/classics.html',
                controller: 'PageController',
                controllerAs: 'vm'
            })
            // .state('connectsx', {
            //     url: '/connectsx',
            //     params: { color: null },
            //     templateUrl: 'app/pages/connectsx.html',
            //     controller: 'PageController',
            //     controllerAs: 'vm'
            // })
            // .state('projects', {
            //     url: '/projects',
            //     params: { color: null },
            //     templateUrl: 'app/pages/projects.html',
            //     controller: 'PageController',
            //     controllerAs: 'vm'
            // })
            // .state('semy', {
            //     url: '/semy',
            //     params: { color: null },
            //     templateUrl: 'app/pages/semy.html',
            //     controller: 'PageController',
            //     controllerAs: 'vm'
            // })
            // .state('tools', {
            //     url: '/tools',
            //     params: { color: null },
            //     templateUrl: 'app/pages/tools.html',
            //     controller: 'PageController',
            //     controllerAs: 'vm'
            // })
            .state('uchicago', {
                url: '/uchicago',
                params: { color: null },
                templateUrl: 'app/pages/uchicago.html',
                controller: 'PageController',
                controllerAs: 'vm'
            });
            // .state('web', {
            //     url: '/web',
            //     params: { color: null },
            //     templateUrl: 'app/pages/web.html',
            //     controller: 'PageController',
            //     controllerAs: 'vm'
            // })
            // .state('ios', {
            //     url: '/ios',
            //     params: { color: null },
            //     templateUrl: 'app/pages/ios.html',
            //     controller: 'PageController',
            //     controllerAs: 'vm'
            // })
            // .state('android', {
            //     url: '/android',
            //     params: { color: null },
            //     templateUrl: 'app/pages/android.html',
            //     controller: 'PageController',
            //     controllerAs: 'vm'
            // });

    }

})();



(function () {
    'use strict';

    angular
        .module('appsaurus')
        .factory('Random', Random);

    Random.$inject = [];

    function Random() {

        function randomIndex(length) {
            return Math.floor(Math.random() * length);
        }

        function shuffle(array) {

            var currentIndex = array.length
                , temporaryValue
                , randIndex
            ;

            // While there remain elements to shuffle...
            while (0 !== currentIndex) {

                // Pick a remaining element...
                randIndex = randomIndex(currentIndex);
                currentIndex -= 1;

                // And swap it with the current element.
                temporaryValue = array[currentIndex];
                array[currentIndex] = array[randIndex];
                array[randIndex] = temporaryValue;
            }

            return array;
        }

        return {
            index: randomIndex,
            ize: shuffle
        }

    }

})();

(function () {
  'use strict';

  angular
    .module('appsaurus')
    .config(config);

  config.$inject = ['$locationProvider', '$urlRouterProvider'];

  function config($locationProvider, $urlRouterProvider) {

    $locationProvider.html5Mode(true);
    $urlRouterProvider.otherwise('/');

  }

})();

(function () {
    'use strict';

    angular
        .module('appsaurus')
        .factory('Colors', Colors);

    Colors.$inject = ['Random'];

    function Colors(Random) {

        // var colors = [
        //     '#b9f7ef',
        //     '#e22367',
        //     '#9C27B0',
        //     '#673AB7',
        //     '#009688',
        //     '#00BCD3',
        //     '#ae3451',
        //     '#14717c',
        //     '#3F51B5',
        //     '#4CAF50',
        //     '#8BC24A',
        //     '#CCDB39',
        //     '#7c72a0',
        //     '#FEC007',
        //     '#607D8B',
        //     '#9E9E9E',
        //     '#FE5722',
        //     '#FE9800',
        // ];

        var colors = [
            '#A6A6A6',
            '#713c4b',
            '#683d74',
            '#595959',
            '#009688',
            '#262626',
            '#683344',
            '#14717c',
            '#2d632d',
            '#8bc24a',
            '#786247',
            '#7c72a0',
            '#fec007',
            // '#0D0D0D',
            '#9e9e9e',
            '#a1503d',
            '#fe9800',
            '#b9f7ef',
            '#e22367',
            '#9C27B0',
            '#673AB7',
            '#009688',
            '#00BCD3',
            '#ae3451',
            '#14717c',
            '#4CAF50',
            '#8BC24A',
            '#CCDB39',
            '#7c72a0',
            '#FEC007',
            '#607D8B',
            '#9E9E9E',
            '#FE5722',
            '#FE9800',
        ];

        return {
            random: function () {
                return colors[Random.index(colors.length)];
            },
        };

    }

})();

(function () {
    'use strict';

    angular
        .module('appsaurus')
        .factory('Animations', Animations);

    Animations.$inject = ['Random'];

    function Animations(Random) {

        var animations = [
            'rubberBand',
            'shake',
            'headShake',
            'swing',
            'tada',
            'wobble',
            'jello'];

        return {
            random: function () {
                return animations[Random.index(animations.length)];
            }
        };

    }

})();

angular.module("appsaurus").run(["$templateCache", function($templateCache) {$templateCache.put("app/dashboard/dashboard.html","<div layout=\"column\" style=\"padding:4px;\" flex=\"\"><md-content flex=\"\" class=\"tile-container\"><md-grid-list md-cols-gt-md=\"8\" md-cols=\"3\" md-cols-md=\"8\" md-row-height=\"1:1\" md-gutter=\"4px\"><md-grid-tile ng-repeat=\"tile in vm.colorTiles | filter:search\" ng-style=\"{ \'background\': tile.color }\" md-colspan=\"{{tile.colspan}}\" md-rowspan=\"{{tile.rowspan}}\" ng-class=\"tile.clazz\" ng-click=\"tile.fun($event)\" id=\"{{ tile.id }}\"><div class=\"tile-background\" ng-style=\"{\'background-image\':\'url({{tile.img}})\', \'background-size\':\'{{tile.size || 90}}% auto\'}\"><div class=\"tile-title\">{{ tile.title }}</div></div></md-grid-tile></md-grid-list></md-content></div>");
$templateCache.put("app/pages/about.html","<md-content layout=\"column\" style=\"padding:4px;\" flex=\"\"><div flex=\"\" layout=\"row\" layout-align=\"center center\" class=\"header\" ng-style=\"{ \'background\': vm.color }\"><img src=\"../../assets/hi.svg\"></div><div layout-padding=\"\"><h3>About</h3><p>I\'m a software engineer based in Kentucky.</p><p>Built <a ng-style=\"{ color:vm.color }\" href=\"https://apps.apple.com/us/app/attikos/id522497233\">Attikos</a> and <a ng-style=\"{ color:vm.color }\" href=\"https://apps.apple.com/us/app/logeion/id727860177\">Logeion</a> for iOS in 2011 and 2012, respectively.</p><p>Founded Appsaurus in 2015.</p><p>CTO @ <a ng-style=\"{ color:vm.color }\" href=\"https://okayinside.com\">Ego & Id</a> from 2019-2022.</p><p>Currently @ <a ng-style=\"{ color:vm.color }\" href=\"https://tensure.io\">Tensure</a>.</p><a ng-style=\"{ color:vm.color }\" href=\"mailto:hi@dayj.io?subject=Hi%20Josh%21&body=Let%27s%20chat%20about%20my%20project%21\">Send me an email.</a><div style=\"height:50px;\"></div></div></md-content>");
$templateCache.put("app/pages/appsaurus.html","<md-content layout=\"column\" style=\"padding:4px;\" flex=\"\"><div flex=\"\" layout=\"row\" layout-align=\"center center\" class=\"header\" ng-style=\"{ \'background\': vm.color }\"><img src=\"../../assets/background.png\"></div><div flex=\"\" layout-padding=\"\"><h3>Co-founded <a ng-style=\"{ color:vm.color }\" href=\"https://appsaur.us\">Appsaurus</a> in 2015.</h3><p>We\'ve worked with ~15 different companies of all different sizes.</p><p>We\'ve built everything: APIs, iOS and Android apps, data processing pipelines, admin consoles, and landing pages.</p><p>You can learn more about the Appsaurus\' projects <a ng-style=\"{ color:vm.color }\" href=\"https://appsaur.us/all-work\">here</a>.</p><div style=\"height:50px;\"></div></div></md-content>");
$templateCache.put("app/pages/classics.html","<md-content layout=\"column\" style=\"padding:4px;\" flex=\"\"><div flex=\"\" layout=\"row\" layout-align=\"center center\" class=\"header\" ng-style=\"{ \'background\': vm.color }\"><img style=\"height:50%;\" src=\"../../assets/a-l.svg\"><div style=\"width:64px\"></div><div style=\"margin-top:32px; margin-bottom:32px;\"><iframe src=\"https://player.vimeo.com/video/243728500?autoplay=1&loop=1&title=0&portrait=0\" width=\"360\" height=\"270\" frameborder=\"0\" webkitallowfullscreen=\"\" mozallowfullscreen=\"\" allowfullscreen=\"\"></iframe></div></div><div layout-padding=\"\" flex=\"\"><h1>Classics</h1><p>@UChicago I worked with Prof. Helma Dik and others to develop <a href=\"https://attikos.org\">Attikos</a> and <a href=\"https://apps.apple.com/us/app/logeion/id727860177\">Logeion</a> on iOS.</p><p><a href=\"https://www.nytimes.com/2014/08/24/books/review/gateways-to-the-classical-world.html\">Logeion in The New York Times</a></p></div></md-content>");
$templateCache.put("app/pages/okay.html","<md-content layout=\"column\" style=\"padding:4px;\" flex=\"\"><div flex=\"\" layout=\"row\" layout-align=\"center center\" class=\"header\" ng-style=\"{ \'background\': vm.color }\"><img style=\"width: 75%; height:50%;\" src=\"../../assets/okaybanner.svg\"></div><div flex=\"\" layout-padding=\"\"><p>CTO @ <a ng-style=\"{ color:vm.color }\" href=\"https://okayinside.com\">Ego & Id</a> from 2019-2022.</p><p>Built <a ng-style=\"{ color:vm.color }\" href=\"https://apps.apple.com/us/app/okay-inside-habits-routines/id1517128447\">Okay Inside for iOS</a>.</p><div style=\"height:50px;\"></div></div></md-content>");
$templateCache.put("app/pages/uchicago.html","<md-content layout=\"column\" style=\"padding:4px;\" flex=\"\"><div flex=\"\" layout=\"row\" layout-align=\"center center\" class=\"header\" ng-style=\"{ \'background\': vm.color }\"><img style=\"width:60%;\" src=\"../../assets/uchicago-logo.png\"></div><div layout-padding=\"\" flex=\"\"><h3>The University of Chicago</h3><p>2015 <a ng-style=\"{ \'color\': vm.color }\" href=\"https://www.chicagotribune.com/business/blue-sky/ct-open-macro-chicago-app-challenge-bsi-20150610-story.html\">Open Macro won the Mobile App Challenge</a>.</p><p>2015 Masters in Computer Science</p></div></md-content>");}]);